:root {
    --h2: clamp(1.5625rem, 1.2591rem + 1.2945vw, 2.8125rem);
    --h3: clamp(1rem, 0.7876rem + 0.9061vw, 1.875rem);
    --fluid-16-25: clamp(1rem, 0.8635rem + 0.5825vw, 1.5625rem);
    --fluid-14-20: clamp(0.875rem, 0.784rem + 0.3883vw, 1.25rem);
    --fluid-21-45: clamp(1.3125rem, 0.5625rem + 1.875vw, 2.8125rem);
    --fluid-29-44: clamp(1.8125rem, 1.585rem + 0.9709vw, 2.75rem);
}

.desktop {
    display: block !important;
    width: 100%;
}

.mobile {
    display: none !important;
    width: 100%;
}

.mobileBR {
    display: none !important;
}

@media (max-width: 640px) {
    .desktop {
        display: none !important;
    }

    .mobile {
        display: block !important;
    }

    .mobileBR {
        display: block !important;
    }
}

.appContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.h2 {
    font-size: var(--h2);
    font-weight: 700;
    text-align: center;
}

.h3 {
    font-size: var(--h3);
    font-weight: 700;
}

html {
    scroll-behavior: smooth;
    font-family: "Segoe UI", sans-serif;
}

body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.logoText {
    transform: rotate(-25deg);
    -ms-transform: rotate(-25deg);
    /*  for IE  */

    /* 	for browsers supporting webkit (such as chrome, firefox, safari etc.). */
    -webkit-transform: rotate(-25deg);
    display: inline-block;
    font-size: var(--fluid-16-25);
}

.heroCont {
    width: 100%;
    position: relative;
}

nav {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E9E8E6;
}

.navWrapper {
    display: flex;
    width: 95%;
    justify-content: space-between;
    padding-block: .5rem 1rem;
    position: relative;
}

.navButtons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navButtons>ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.navLogo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.navLogo>h1 {
    font-size: var(--fluid-29-44);
    font-weight: 700;
}

.navLogo>p {
    font-size: var(--fluid-14-20);
}

@media (max-width: 640px) {
    .navWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }
}

.overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 30%;
    left: 0;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    z-index: 0;
}

a:hover {
    cursor: pointer;
}

.faqBG {
    background-image: url(./assets/headerPic.webp);
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 1440px;
    border-radius: 5px;
    box-shadow: 0 0 20px;
    margin-top: 3rem;
}

.choice {
    border: 2px solid black;
    border-radius: 50%;
}

.row-content-text {
    text-align: start;
}

/* ----------------------------MEDIA QUERIES---------------------------- */

/* Media Query for react-faq-component since tailwind doesn't override styles */
@media (max-width: 639px) {
    .row-title-text {
        font-size: 10px !important;
        font-weight: 600 !important;
        text-align: start;
    }

    .row-content-text {
        font-size: 10px !important;
    }

    .overlay {
        top: 200px
    }
}

.navItem {
    font-size: var(--fluid-16-25);
    font-weight: 700;
    background-color: transparent;
    padding: .5em 1em;
    border-radius: 5px;
    border: 2px solid #000;
    font-size: var(--fluid-14-20);
    transition: ease-in .3s;
    width: 280px;
}

.navItem:hover {
    background-color: #000;
    border: 2px solid #000;
    color: #fff;
}

/* Hours Component CSS */
.hoursCont {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-block: 2rem;
    background-color: #E9E8E6;
    margin-top: 2rem;
    width: 100%;
}

.hoursWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.hoursWrapper>h3 {
    text-decoration: underline;
}

.hoursWrapper>p {
    color: red;
    font-weight: 500;
}

.hoursUL {
    width: 90%;
    display: flex;
    flex-direction: column;
}

.hours {
    font-size: var(--fluid-14-20);
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    font-weight: 700;
}

.hoursSpan {
    font-weight: 400;
}

/* Contact Us */
.contactUs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-block: 2rem;
    width: 100%;
    background-color: #E9E8E6;
    margin-top: 3rem;
}

.contactWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 1rem;
    max-width: 1440px;
}

.contactCard {
    width: 33%;
    padding: 1rem;
    display: flex;
    gap: .5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.phoneCard {}

.phoneCard>p {
    font-size: var(--fluid-14-20);
}

.phoneCard>a>span {
    text-decoration: underline;
}

.addressCard>p {
    font-size: var(--fluid-14-20);
}

.contactCard>img {
    width: 30%;
}

.contactUs>h2 {
    margin-bottom: 1rem;
}

.directionsBtn {
    border: #000 solid 2px;
    padding: .5rem 1rem;
    transition: ease-in .3s;
    border-radius: 5px;
}

.directionsBtn:hover {
    background-color: #000;
    color: #fff;
    padding: .5rem 1rem;
}

@media (max-width: 768px) {
    .contactWrapper {
        flex-direction: column;
        align-items: center;
    }

    .contactCard {
        width: 90%;
    }

    .contactCard>img {
        width: 20%;
    }
}

/* Forms Container */
.formsCont {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    background-color: #E9E8E6;
    width: 100%;
}

.formBtn {
    background-color: transparent;
    padding: .5rem 1rem;
    border-radius: 5px;
    border: 2px solid #000;
    font-size: var(--fluid-14-20);
    transition: ease-in .3s;
    width: 280px;
}

.formBtn:hover {
    background-color: #000;
    border: 2px solid #000;
    color: #fff;
}

.formWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.formBtns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
}

.allTaxPayersForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
    width: 90%;
    max-width: 1000px;
}

.allTaxPayersForm>a {
    width: 100%;
}

.allTaxPayersForm>a>img {
    width: 100%;
}

.allTaxPayersForm>p {
    font-size: var(--fluid-16-25);
    font-weight: 500;
}

/* FAQ Container */
.faqCont {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 2rem;
    gap: 1rem;
}

.faqCont>h2 {
    margin-bottom: 1rem;
}

.creditCardMsg {
    color: #ff0000;
    font-weight: 700;
}

.aboutMessage {
    color: #fff;
    font-size: var(--fluid-21-45);
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
}

.formPic {
    display: flex;
    justify-content: center;
}

.formPic>img {
    width: 90%;
}


@media (max-width: 768px) {
    .test {
        grid-template-columns: repeat(2, 1fr);
    }

    .formBtns {
        flex-direction: column;
    }

    .creditCardMsg {
        font-size: 12px;
    }

    .faqDescription {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .test {
        grid-template-columns: repeat(1, 1fr);
    }
}

.formContainer {
    padding-block: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.formContainer>a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.generalEngagementForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.generalWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    box-shadow: none;
    transition: box-shadow ease-in .3s;
    padding-bottom: 2rem;
}

.generalWrapper>h3 {
    font-size: var(--fluid-14-20);
    font-weight: 700;
    text-decoration: underline;
    text-align: center;
}

.generalWrapper>h4 {
    margin-top: .5rem;
    font-weight: 700;
}

.generalWrapper>ul {
    list-style: disc;
    padding-left: 2rem;
}

.header {
    text-align: center;
    margin-bottom: 1rem;
    font-weight: 700;
}

.boldLI {
    font-weight: 700;
}

.signature {
    border-top: 1px solid #000;
    display: flex;
    justify-content: space-between;
    margin-block: 2rem;
}

.signature>p:last-child {
    padding-right: 5rem;
}

/* TAX PAYER FORM */
.taxInfoSheetCont {
    width: 100%;
    display: flex;
    justify-content: center;
}

.taxInfoWrapper {
    width: 90%;
}

.taxInfoWrapper>h3 {
    text-align: center;
    font-size: 25px;
    font-weight: 700;
}

.clientType {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;
}

.info1 {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    gap: 1rem;
}

.info1>div {
    width: 50%;
}

.info1>div>p {
    border-bottom: 1px solid #000;
}

.questionMark {
    display: inline-block;
    border: 1px solid #000;
    line-height: .2;
    font-size: 13px;
    height: 16px;
    width: 15px;
    text-align: center;
    box-sizing: border-box;
}

.prepListCont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: .5rem;
    width: 90%;
    padding: 2rem 2rem 10rem 2rem;
    background-color: #fff;
    box-shadow: none;
    transition: box-shadow ease-in .3s;
}

.prepListCont:hover {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.prepListCont>h1 {
    font-size: var(--fluid-14-20);
    font-weight: 700;
    text-decoration: underline;
    text-align: center;
}

.thankYou {
    margin-top: 2rem;
}

.afterLooking {
    margin-top: 2rem;
}

.icon-wrapper {
    font-weight: 800 !important;
}

.prepListDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.prepListDiv>p {
    font-size: var(--fluid-16-25);
    font-weight: 500;
    margin-bottom: 1rem;
}

.prepListDiv>a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.genEngageA {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.genEngageA>p {
    font-size: var(--fluid-16-25);
    font-weight: 500;
    margin-bottom: 1rem;
}

.daycare {
    justify-content: space-evenly;
}

.daycareExpenseDiv {
    display: flex;
    justify-content: space-around;
    margin-bottom: 5px;
}

.yesNo {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: max-content !important;
    gap: 1rem;
}

.taxPayersForm * {
    width: 100%;
}

@media print {
    .noPrint {
        display: none;
    }

    select {
        -moz-appearance: none;
        /* Firefox */
        -webkit-appearance: none;
        /* Safari and Chrome */
        appearance: none;
    }

    ::-webkit-input-placeholder {
        /* WebKit browsers */
        color: transparent;
    }

    :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: transparent;
    }

    ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: transparent;
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: transparent;
    }

    input::placeholder {
        color: transparent;
    }

    textarea::-webkit-input-placeholder {
        /* WebKit browsers */
        color: transparent;
    }

    textarea:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: transparent;
    }

    textarea::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: transparent;
    }

    textarea:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: transparent;
    }

    textarea::placeholder {
        color: transparent;
    }
}

select {
    padding-inline: .25rem;
}